import { useMutation } from '@tanstack/react-query';
import DefaultImage from '_common/component/DefaultImage';
import ModalChangePassword from '_common/component/ModalChangePassword';
import { useStores } from '_common/hooks';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthApi } from 'states/api';
import { ReactComponent as IconExit } from 'styles/images/Icons/IconExit.svg';
import { EnvelopeOutlined, Popover, notification } from 'tera-dls';

const User = observer(() => {
  const [modalResetPassword, setModalResetPassword] = useState<boolean>(false);
  const {
    authStore: { user, clear },
    commonStore: { clear: clearCRM },
  } = useStores();
  const navigate = useNavigate();

  const { mutate: onLogout } = useMutation(AuthApi.logout, {
    onSuccess: () => {
      clear();
      clearCRM();
    },
    onError: (error: any) => {
      clear();
      clearCRM();
      const errorMessage = error?.msg;

      notification.error({
        message: errorMessage,
      });
    },
  });

  const Content = () => {
    const classItem =
      'p-[6px] [&:not(:last-child)]:border-b hover:bg-gray-50 cursor-pointer';

    return (
      <div className="p-2.5 rounded-2.5 w-[200px] flex flex-col gap-y-5">
        <div className="flex flex-col gap-y-[14px]">
          <h3 className="text-blue-800">Tài khoản</h3>
          <div className="flex flex-col gap-y-[7px]">
            <div className="flex items-center gap-x-[7px]">
              <DefaultImage
                src={user?.avatar}
                alt={user?.full_name}
                className="w-10 h-10 rounded-full border shrink-0 "
              />
              <div className="flex flex-col justify-center gap-x-[5px] w-full">
                <span className="text-blue-500 line-clamp-1 break-word">
                  {user?.full_name}
                </span>
                <span className="text-[10px] text-gray-500 line-clamp-1 break-word">
                  {user?.role_name}
                </span>
              </div>
            </div>
            {user?.email && (
              <p className="flex items-center gap-x-[5px]">
                <EnvelopeOutlined className="w-4 h-4" />
                <span className="truncate">{user?.email}</span>
              </p>
            )}
          </div>
        </div>
        <div>
          <h3 className="text-blue-800 mb-[5px]">Khác</h3>
          <ul>
            {/* <li
              className={classItem}
              // onClick={() => setModalUpdateInformation(true)}
            >
              Chỉnh sửa thông tin
            </li> */}
            <li
              className={classItem}
              onClick={() => setModalResetPassword(true)}
            >
              Đổi mật khẩu
            </li>
          </ul>
        </div>
        <div>
          <p
            className="flex gap-x-[5px] text-red-500 itemns-center p-[6px] hover:bg-gray-50 cursor-pointer"
            onClick={() => onLogout()}
          >
            <IconExit />
            <span>Thoát</span>
          </p>
        </div>
      </div>
    );
  };

  return (
    <>
      <Popover
        trigger="click"
        content={<Content />}
        placement="bottom-end"
        showArrow={false}
        className="border"
      >
        <div className="relative">
          <DefaultImage
            src={user?.avatar}
            className="w-6 h-6 rounded-full border cursor-pointer"
            alt="avatar-user"
          />
          <div className="absolute w-2 h-2 rounded-full bg-green-500 bottom-0 right-0 border" />
        </div>
      </Popover>
      {modalResetPassword && (
        <ModalChangePassword
          userId={user?.id}
          open={modalResetPassword}
          onClose={() => setModalResetPassword(false)}
          onSuccess={() => navigate('/auth/login')}
        />
      )}
    </>
  );
});

export default User;
